define("discourse/plugins/discourse-akismet/discourse/components/reviewable-akismet-post", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ReviewableTopicLink @reviewable={{this.reviewable}} @tagName="" />
  
  <div class="post-contents-wrapper">
    <ReviewableCreatedBy
      @user={{this.reviewable.target_created_by}}
      @tagName=""
    />
  
    <div class="post-contents">
      <ReviewableCreatedByName
        @user={{this.reviewable.target_created_by}}
        @tagName=""
      />
  
      <div class="post-body">
        {{html-safe this.reviewable.payload.post_cooked}}
      </div>
  
      {{yield}}
  
      {{#if this.reviewable.payload.external_error}}
        <ReviewableAkismetApiError
          @external_error={{this.reviewable.payload.external_error}}
        />
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "5N+NGjJ7",
    "block": "[[[8,[39,0],null,[[\"@reviewable\",\"@tagName\"],[[30,0,[\"reviewable\"]],\"\"]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"post-contents-wrapper\"],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@user\",\"@tagName\"],[[30,0,[\"reviewable\",\"target_created_by\"]],\"\"]],null],[1,\"\\n\\n  \"],[10,0],[14,0,\"post-contents\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@user\",\"@tagName\"],[[30,0,[\"reviewable\",\"target_created_by\"]],\"\"]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"post-body\"],[12],[1,\"\\n      \"],[1,[28,[35,4],[[30,0,[\"reviewable\",\"payload\",\"post_cooked\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[18,1,null],[1,\"\\n\\n\"],[41,[30,0,[\"reviewable\",\"payload\",\"external_error\"]],[[[1,\"      \"],[8,[39,7],null,[[\"@external_error\"],[[30,0,[\"reviewable\",\"payload\",\"external_error\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"reviewable-topic-link\",\"div\",\"reviewable-created-by\",\"reviewable-created-by-name\",\"html-safe\",\"yield\",\"if\",\"reviewable-akismet-api-error\"]]",
    "moduleName": "discourse/plugins/discourse-akismet/discourse/components/reviewable-akismet-post.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, class extends _component.default {});
});